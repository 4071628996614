<template>
  <div>
    <h5 class="text-left text-success">{{ section }}</h5>
    <hr />
    <div class="row performance">
      <div class="col-lg-6 performanceChart-box">
        <div
          v-if="section === 'Reading' || section === 'Listening'"
          class="performanceChart"
          ref="performanceChart"
        />
      </div>
      <div class="col-lg-6" style="display: flex;overflow: auto">
        <table class="table table-bordered">
          <thead>
            <tr>
              <!-- <th style="width:50%">{{ section }}</th> -->
              <th>{{ $t("pageTitle.Question Type") }}</th>
              <th>{{ $t("pageTitle.My Average") }}</th>
              <th>{{ $t("pageTitle.Average") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(tag, index) in tags" :key="index">
              <!-- <td v-if="index === 0" :rowspan="tags.length">
              <div class="performanceChart-box">
                <div
                  v-if="section === 'Reading' || section === 'Listening'"
                  class="performanceChart"
                  ref="performanceChart"
                />
              </div>
            </td> -->
              <td>
                <b>
                  {{ tag.name[lang] }}
                </b>
              </td>
              <td v-html="getAvg(tag.correct_count, tag.question_count)"></td>
              <td
                v-html="
                  getAvg(tag.total_correct_count, tag.total_question_count)
                "
              ></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from "echarts";

export default {
  components: {},

  mixins: [],

  props: ["section", "tags"],
  data() {
    return {
      charts: null
    };
  },
  computed: {
    lang() {
      return this.$store.getters["user/langShortValue"];
    }
  },
  watch: {
    tags() {
      if (this.section === "Reading" || this.section === "Listening") {
        this.$nextTick(() => {
          this.draw();
        });
      }
    }
  },

  mounted() {},

  methods: {
    draw() {
      this.charts = echarts.init(this.$refs["performanceChart"]);
      let chartData = [];
      let indicator = [];
      let max = 0;
      switch (this.section) {
        case "Reading":
          max = 100;
          break;
        case "Listening":
          max = 100;
          break;
        case "Speaking":
          max = 4;
          break;
        case "Writing":
          max = 5;
          break;
        default:
      }
      let myAccuracy = [];
      let average = [];
      let myAverage = [];
      for (let key in this.tags) {
        let tag = this.tags[key];
        indicator.push({
          name: tag.name[this.lang],
          max: max
        });
        // myAccuracy.push(this.getPer(tag.correct_count, tag.question_count));
        average.push(
          this.getPer(tag.total_correct_count, tag.total_question_count)
        );
        myAverage.push(this.getPer(tag.correct_count, tag.question_count));
      }
      chartData.push({
        name: this.$t("pageTitle.My Average"),
        value: myAverage
      });
      chartData.push({
        name: this.$t("pageTitle.Average"),
        value: average
      });
      const option = {
        color: ["#ff8920", "#409EFF", "#212529"],
        legend: {
          data: [
            this.$t("pageTitle.My Accuracy"),
            this.$t("pageTitle.My Average"),
            this.$t("pageTitle.Average")
          ]
        },
        radar: {
          // shape: 'circle',
          indicator: indicator,
          splitArea: {
            show: true,
            areaStyle: {
              color: ["#fff"] // 图表背景网格的颜色
            }
          },
          nameGap: 10,
          name: {
            rich: {
              a: {
                color: "white",
                align: "center",
                backgroundColor: "#42a16a",
                padding: 5,
                borderRadius: 4,
                fontWeight: 700
              }
            },
            formatter: a => {
              return `{a|${a}}`;
            }
          }
        },
        series: [
          {
            type: "radar",
            data: chartData
          }
        ]
      };
      this.charts.setOption(option);
    },
    getPer(correctQuestionsCount, questionsCount) {
      let correctCount = correctQuestionsCount;
      let allCount = questionsCount;
      let percent = Math.round((correctCount / allCount) * 100);
      if (correctQuestionsCount >= 0 && questionsCount >= 0) {
        switch (this.section) {
          case "Reading":
            return percent;
          case "Listening":
            return percent;
          case "Speaking":
            return 4;
          case "Writing":
            return 5;
          default:
        }
      } else {
        return 0;
      }
    },
    getAverageScore(exam_count, question_count) {
      let maxScore = 5;
      if (this.section === "Speaking") {
        maxScore = 4;
      }
      if (exam_count > 0 && question_count > 0) {
        // chapter 的正確數量 / (這張考券的題目數量 / 5)
        let score = (exam_count / question_count).toFixed(2);
        return `<b class="text-success">${Number(score)}/${maxScore} </b> `;
      } else {
        return "<i class='fas fa-lock'></i>";
      }
    },
    getAccuracy(correctQuestionsCount, questionsCount) {
      let percent = Math.round((correctQuestionsCount / questionsCount) * 100);
      const displayAccuracy = `${correctQuestionsCount}/${questionsCount}`;
      if (correctQuestionsCount >= 0 && questionsCount >= 0) {
        if (percent <= 50) {
          return `<b class="text-danger">${displayAccuracy} (${percent}%)</b> `;
        } else {
          return `<b class="text-success">${displayAccuracy} (${percent}%)</b> `;
        }
      } else {
        return "<i class='fas fa-lock'></i>";
      }
    },
    getAvg(correctQuestionsCount, questionsCount) {
      let percent = Math.round((correctQuestionsCount / questionsCount) * 100);
      if (correctQuestionsCount >= 0 && questionsCount >= 0) {
        if (percent <= 50) {
          return `<b class="text-danger">${percent}%</b> `;
        } else {
          return `<b class="text-success">${percent}%</b> `;
        }
      } else {
        return "<i class='fas fa-lock'></i>";
      }
    }
  }
};
</script>

<style scoped>
.table-box {
  width: 100%;
  overflow: auto;
}

.title {
  color: #494949;
  font-size: 20px;
  border: 0;
  padding: 0;
  border-left: 4px solid var(--themeColor);
  padding-left: 15px;
  /* line-height: 16px; */
  margin-bottom: 20px;
}
.performance {
  background-color: #f2f3f5;
  padding: 20px 20px 20px 20px;
  border-radius: 5px;
}
.table {
  background-color: white;
  margin: 0;
  border-radius: 5px;
}
::v-deep .view-question {
  display: inline-block;
  height: 30px;
  width: 30px;
  line-height: 28px;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  border-radius: 5px;
  margin: 5px;
}
::v-deep .task-question {
  display: inline-block;
  height: 30px;
  width: 30px;
  line-height: 28px;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  border-radius: 5px;
  margin: 5px;
  background-color: var(--themeColor);
  color: white;
}
::v-deep .correct {
  border: 1px solid var(--themeColor);
}
::v-deep .error {
  border: 1px solid red;
}
/* .performanceChart-box {
  width: 100%;
} */

.performanceChart-box {
  overflow: auto;
}
.performanceChart {
  width: 600px;
  height: 500px;
}
@media screen and (max-width: 1200px) {
  .performance {
    padding: 0;
  }
  .performanceChart {
    width: 600px;
    height: 500px;
  }
}
</style>
